import React from 'react';

import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Projects from './components/Projects';
import Header from './components/header';
import Land from './components/land';
import './App.css';

function App() {
  return (
    <div className="bg-gray-100">
      <Land/>
      {/* <Header/>
      <About />
      <Gallery/>
      <Projects/>
      <Contact /> */}
    </div>
  );
}

export default App;
