import React, { useRef } from 'react';

const UnderConstruction = () => {
  const audioRef = useRef(null);

  const handleImageClick = () => {
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio
    }
  };

  return (
    <section className="py-48 bg-white">
      <div className="container mx-auto text-center">
        <div className="flex justify-center mb-8" onClick={handleImageClick}>
          <img
            src="/images/photo1.jpg"
            alt="Under Construction"
            className="hover:scale-150 rounded-lg w-72"
            style={{  animation: "ghostlyAnimation 5s infinite" }}
          />
        </div>
        <h2 className="text-2xl font-bold text-black mb-4 text-center">
          Website Under Construction
        </h2>
        <p className="text-lg text-center">
          We're sorry, but this page is currently under construction. Please check back later.
        </p>
        <p className="text-xl font-bold text-red-600 mb-4 text-center" style={{ animation: "ghostlyAnimation 5s infinite" }}>
          Don't click on the image
        </p>
        <audio autoPlay loop ref={audioRef}>
          <source src="/bgm.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </section>
  );
};

export default UnderConstruction;
