import React from 'react';

const About = () => {
  return (
    <section className="py-12 text-center">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-4">About Faslim Rahim</h2>
        <p className="text-lg">
          Faslim Rahim is a legendary figure at Ilahia College of Engineering and Technology. Rumor has it that he can
          debug code with his eyes closed and recite the entire HTML specification from memory!
        </p>
      </div>
    </section>
  );
};

export default About;
